<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet :title="title">
          <template v-slot:body>
            <div>
              <b-overlay rounded="sm">
                <b-row>
                  <b-col md="3" lg="3">
                    <b-form-group :label="$t('GENERAL.SELECT_SEMESTER')">
                      <b-form-select
                        v-model="student_class_selected"
                        :options="student_classes"
                        @change="getFeePaymentHistory"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
                <b-row>
                  <b-table
                    striped
                    head-variant="dark"
                    show-empty
                    :busy="isBusy"
                    :items="fee_payment_history"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                  >
                    <template v-slot:empty="scope">
                      <p class="text-center">
                        {{ $t("PAYMENT_HISTORY.NOT_PAID") }}
                      </p>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                      </div>
                    </template>
                  </b-table>
                </b-row>
                <b-row>
                  <b-alert
                    show
                    variant="secondary"
                    md="12"
                    style="color: #000;"
                  >
                    {{ $t("PAYMENT_HISTORY.REMAINING") }}:
                    {{ " " + remaining_fee + " ؋" }}
                  </b-alert>
                </b-row>
              </b-overlay>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";

export default {
  data() {
    return {
      title: this.$t("MENU.PAYMENT_HISTORY"),
      isBusy: true,
      sortBy: "installment",
      sortDesc: false,
      fields: [
        {
          key: "semester",
          label: this.$t("GENERAL.SEMESTER"),
          sortable: true
        },
        {
          key: "payment_date",
          label: this.$t("PAYMENT_HISTORY.PAYMENT_DATE"),
          sortable: true
        },
        {
          key: "fee_type",
          label: this.$t("PAYMENT_HISTORY.FEE_TYPE"),
          sortable: true
        },
        {
          key: "installment",
          label: this.$t("PAYMENT_HISTORY.INSTALLMENT"),
          sortable: true
        },
        {
          key: "total_amount",
          label: this.$t("PAYMENT_HISTORY.TOTAL_AMOUNT"),
          sortable: true
        },
        {
          key: "discount",
          label: this.$t("PAYMENT_HISTORY.DISCOUNT"),
          sortable: true
        },
        {
          key: "paid_amount",
          label: this.$t("PAYMENT_HISTORY.PAID_AMOUNT"),
          sortable: true
        }
      ],
      fee_payment_history: [],
      student_classes: [],
      student_class_selected: 0,
      remaining_fee: 0
    };
  },
  components: {
    KTPortlet
  },
  methods: {
    getFeePaymentHistory() {
      this.isBusy = true;
      let filters = {
        student_class_id: this.student_class_selected
      };
      ApiService.post("student/payment-history", filters).then(({ data }) => {
        this.fee_payment_history = data.fee_payment_history;
        this.remaining_fee = data.remaining_fee;
        this.isBusy = false;
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.PAYMENT_HISTORY"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.get("student/enrolled-classes/payment-history").then(
      ({ data }) => {
        this.student_classes = data.student_classes;
        this.student_class_selected =
          data.student_classes[0] !== undefined
            ? this.student_classes[0].value
            : 0;
        this.getFeePaymentHistory();
      }
    );
  }
};
</script>
